import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from '../ContentfulText';
import ButtonLink from '../UI/ButtonLink';
import Container from '../Container';

const Hero = styled.section`
  color: white;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    justify-content: space-between;
    flex-direction: row;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 142px 0;
  }

  &.annual-subscription-hero {
    @media (min-width: 1440px) and (max-width: 2511px) {
      padding: 190px 0;
    }
  }

  p {
    margin: 0;
  }
`;

const Badge = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryRed};
  font-family: ${({ theme }) => theme.fonts.inter};
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.mobileParagraph};
  text-align: center;
  margin-bottom: 8px;
  display: inline-block;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
    margin-bottom: 10px;
  }
`;

const StyledHero = styled.section`
  overflow: hidden;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 32px 40px 0;
    border-radius: 25px;
    background-position: right;
  }

  @media (min-width: 1680px) {
    max-width: 1585px;
    margin: 32px auto 0;
  }
`;

const Image = styled.img`
  position: absolute;
  top: -50px;
  right: -110px;
  max-width: 412px;

  @media (max-width: 375px) {
    bottom: 0;
    right: -170px;
  }

  @media ${({ theme }) => theme.breakpoints.tablet} {
    bottom: 0;
    right: -150px;
    max-width: 450px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 700px;
    right: -215px;
    top: -40px;
  }

  @media (min-width: 1200px) {
    max-width: 750px;
    top: -50px;
  }
`;

const ImageContainer = styled.div`
  flex-grow: 1;
`;

const Content = styled(Container)`
  order: 1;
  margin: 375px 0 80px;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: 55%;
    order: 0;
    margin: 112px 0 122px 4.5vw;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    order: 0;
    margin: 0;
  }

  h1 {
    font-size: 2rem;
    line-height: 2.375rem;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: ${({ theme }) => theme.fontSizes.desktopHeader};
      line-height: 4.5rem;
      margin-bottom: 0;
    }
  }

  p {
    font-family: ${({ theme }) => theme.fonts.inter};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
    line-height: ${({ theme }) => theme.lineHeights.desktopParagraph};
    max-width: 700px;
  }

  p:empty {
    display: none;
  }

  a {
    margin-top: 16px;
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      width: 285px;
      margin-top: 32px;
    }
  }
`;

const MainHero = ({ data }) => {
  const {
    backgroundImage,
    title,
    description,
    badge,
    callToAction,
    image,
  } = data;

  const mainHeroClassName =
    !description && !callToAction ? 'annual-subscription-hero' : '';

  return (
    <StyledHero backgroundImage={backgroundImage.asset.url}>
      <Hero className={mainHeroClassName}>
        <Content>
          {badge && <Badge>{badge}</Badge>}
          <Text document={title.json} />
          {description && <Text document={description.json} />}
          {callToAction && (
            <ButtonLink
              route="external"
              url={callToAction.linkUrl}
              customStyle={[callToAction.style, 'block']}
            >
              {callToAction.linkText}
            </ButtonLink>
          )}
        </Content>
        <ImageContainer>
          <Image src={image.asset.url} alt={image.altText} aria-hidden />
        </ImageContainer>
      </Hero>
    </StyledHero>
  );
};

MainHero.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default MainHero;
