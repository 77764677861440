import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import ButtonLink from '../UI/ButtonLink';
import Wrapper from '../Wrapper';
import Text from '../ContentfulText';

const FaqSection = styled.section`
  display: flex;
  color: ${({ theme }) => theme.colors.secondaryGrey};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: cover;
  border-radius: 24px;
  background-repeat: no-repeat;
  margin-bottom: 24px;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
    margin-bottom: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 80px;
  }

  a {
    margin-top: 16px;
    font-size: ${({ theme }) => theme.fontSizes.mobileLink};
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      margin-top: 24px;
      max-width: 235px;
    }

    @media ${({ theme }) => theme.breakpoints.tablet} {
      margin-top: 24px;
      max-width: 235px;
    }
  }

  h1 {
    margin-bottom: 8px;
    font-size: ${({ theme }) => theme.fontSizes.tabletCallOut};

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: ${({ theme }) => theme.fontSizes.desktopCallOut};
    }
  }

  p {
    font-family: ${({ theme }) => theme.fonts.inter};
    font-size: ${({ theme }) => theme.fontSizes.mobileParagraph};
    line-height: ${({ theme }) => theme.lineHeights.mobileParagraph};
    margin: 0;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      line-height: ${({ theme }) => theme.lineHeights.desktopParagraph};
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
    }
  }
`;

const FaqSectionContent = styled.div`
  flex-grow: 1;
  padding: 24px;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 64px 54px 54px 64px;
  }
`;

const FaqTile = ({ data }) => {
  const intl = useIntl();
  const { title, description, callToAction, backgroundImage } = data;

  const base = description.json.content[0].content;
  const ariaLabel = `${base[0].value}${
    base[1].data.target.fields.linkText[`${intl.locale}-CA`]
  }${base[2].value}${base[4].value}`;

  return (
    <Wrapper>
      <FaqSection backgroundImage={backgroundImage.asset.url}>
        <FaqSectionContent>
          <Text document={title.json} />
          <Text document={description.json} />
          <ButtonLink
            customStyle={['block', callToAction.style]}
            url={callToAction.linkUrl}
            ariaLabel={ariaLabel}
          >
            {callToAction.linkText}
          </ButtonLink>
        </FaqSectionContent>
      </FaqSection>
    </Wrapper>
  );
};

FaqTile.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default FaqTile;
