import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../components/Layout';
import SEO from '../components/SEO/seo.js';
import PageBuilder from '../components/PageBuilder';
import TextBox from '../components/TextBox';
import Container from '../components/Container';
import FaqTile from '../components/FaqTile';
import MainTable from '../components/MainTable';
import AnnualSubscription from '../components/AnnualSubscription';
import MainHero from '../components/MainHero';
import ValueTiles from '../components/ValueTiles';
import PointsDaysHero from '../components/PointsDaysHero';
import SummerInsidersHero from '../components/SummerInsidersHero';

const StyledHomePage = styled.div`
  padding: 0;
  width: 100%;
  justify-content: space-between;
  background-color: #fcfcfc;
`;

const PaddingTop = styled.div`
  padding-top: 5%;

  @media screen and (max-width: 767px) {
    padding-top: 4rem;
  }
`;

const pageTemplate = {
  // eslint-disable-next-line react/prop-types
  'heading-text-alignment---top': ({ data }) => (
    <Container>
      <PaddingTop>
        <TextBox data={data} />
      </PaddingTop>
    </Container>
  ),
  'main-page-faq-tile': FaqTile,
  'main-page-table': MainTable,
  'main-subscription-section': AnnualSubscription,
  'main-hero': MainHero,
  'points-day-hero': PointsDaysHero,
  'main-value-tiles': ValueTiles,
  'summer-insiders-hero': SummerInsidersHero,
};

const HomePage = ({ data: { contentfulPage, contentfulLayout }, location }) => {
  const intl = useIntl();
  const {
    pageMetaTitle,
    pageMetaDescription,
    pageSections,
    optionalNotificationBanners,
  } = contentfulPage;

  const { header, footer } = contentfulLayout;

  return (
    <Layout
      header={header}
      footer={footer}
      notificationBanners={optionalNotificationBanners}
    >
      <StyledHomePage>
        <SEO
          title={pageMetaTitle}
          description={pageMetaDescription.pageMetaDescription}
          lang={intl?.locale}
          location={location}
        />
        <PageBuilder
          sections={pageSections}
          template={pageTemplate}
          intl={intl}
        />
      </StyledHomePage>
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export const query = graphql`
  query HomePageQuery($locale: String = "en-CA") {
    contentfulLayout(
      internalTitle: { eq: "Main Layout" }
      node_locale: { eq: $locale }
    ) {
      id
      node_locale
      header {
        id
        node_locale
        headerButtons {
          id
          internalName
          linkFrontEndFormat
          linkText
          linkUrl
          style
        }
        icon {
          altText
          asset {
            url
          }
        }
        logo {
          id
          title
          localFile {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationHeight
                presentationWidth
              }
            }
          }
        }
        headerPageLinks {
          id
          linkText
          linkUrl
          style
        }
      }
      footer {
        id
        phoneNumberList {
          id
          json
        }
        linksList {
          id
          json
        }
        downloadAppList {
          id
          json
        }
        googlePlayLink {
          linkUrl
          linkText
          icon {
            altText
            asset {
              url
            }
          }
        }
        appleStoreLink {
          linkUrl
          linkText
          icon {
            altText
            asset {
              url
            }
          }
        }
        trademark {
          id
          json
        }
        node_locale
        footerLogo {
          altText
          asset {
            url
          }
        }
      }
    }
    contentfulPage(internalName: { eq: "Main" }, node_locale: { eq: $locale }) {
      id
      node_locale
      pageMetaTitle
      pageMetaDescription {
        pageMetaDescription
      }
      optionalNotificationBanners {
        ... on ContentfulNotificationBanner {
          id
          isDismissible
          color
          bannerCount
          bannerContent {
            json
          }
        }
      }
      pageSections {
        ... on ContentfulMainHero {
          id
          sectionStyleVariant
          description {
            json
          }
          title {
            json
          }
          badge
          callToAction {
            style
            linkText
            linkUrl
            id
          }
          image {
            id
            altText
            asset {
              url
            }
          }
          backgroundImage {
            asset {
              url
            }
          }
        }
        ... on ContentfulMainSubscriptionSection {
          id
          sectionStyleVariant
          title {
            json
          }
          description {
            json
          }
          backgroundImage {
            id
            altText
            asset {
              url
            }
          }
          subscriptionTiles {
            id
            title {
              json
            }
            image {
              altText
              asset {
                url
              }
            }
            backgroundImage {
              asset {
                url
              }
            }
          }
        }
        ... on ContentfulMainValueTiles {
          id
          sectionStyleVariant
          valueTiles {
            id
            title {
              json
            }
            description {
              json
            }
            icon {
              altText
              id
              asset {
                url
              }
              title
            }
          }
        }
        ... on ContentfulFaqTile {
          id
          sectionStyleVariant
          description {
            json
          }
          title {
            json
          }
          backgroundImage {
            asset {
              url
            }
          }
          callToAction {
            style
            linkText
            linkUrl
            id
          }
        }
        ... on ContentfulMainTable {
          id
          sectionStyleVariant
          mainTableItems {
            id
            text {
              json
            }
            row1 {
              json
            }
            row2 {
              json
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
