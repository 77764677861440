import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  text-align: ${({ textAlign }) => textAlign || 'left'};

  h2:empty,
  p:empty {
    display: none;
  }

  &:first-of-type {
    h2 {
      margin-top: 24px;

      @media ${({ theme }) => theme.breakpoints.desktop} {
        margin-top: 64px;
      }
    }
  }

  h3 {
    font-size: 1.6rem;
    line-height: 1.05;

    @media screen and (max-width: 600px) {
      font-size: 1.3rem;
    }
  }

  ol {
    margin-left: 15px;
    font-family: ${({ theme }) => theme.fonts.inter};
    color: ${({ theme }) => theme.colors.primaryGrey};
  }
`;

export const SubHeading = styled.p`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.primaryGrey};
  font-weight: bold;
  font-size: 0.9375rem;
  margin: 24px 0 8px;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    margin: 32px 0 16px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 40px 0 16px;
  }
`;

export const Heading = styled.h2``;

export const Content = styled.p`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.secondaryGrey};
  line-height: 23px;
  margin-bottom: 1em;
  font-weight: 400;
  font-size: 0.8125rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 0.9375rem;
  }
`;
