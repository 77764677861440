import React from 'react';
import PropTypes from 'prop-types';
import PageLandmark from '../PageLandmark';

const PageBuild = ({ sections, template }) => {
  if (!sections) return null;
  const Page = sections.map(({ sectionStyleVariant, anchorID }, index) => {
    if (anchorID) {
      return <PageLandmark anchor={anchorID} key={anchorID} />;
    }
    if (!sectionStyleVariant) {
      console.warn(
        'A component field is requried on all content models to pass the data appropriately'
      );
      return false;
    }
    const componentType = sectionStyleVariant
      .split(' ')
      .join('-')
      .toLowerCase();
    const templateIds = Object.keys(template);
    if (templateIds.includes(componentType)) {
      const Component = template[componentType];
      return <Component key={sections[index].id} data={sections[index]} />;
    }
    console.warn(
      `Didnt find a component to match the ${sectionStyleVariant} value`
    );
    return false;
  });
  return <>{Page}</>;
};

PageBuild.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  template: PropTypes.shape().isRequired,
};

export default PageBuild;
