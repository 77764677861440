import React from 'react';
import PropTypes from 'prop-types';

import HeaderOffsetDiv from './styles';

const PageLandmark = ({ anchor }) => {
  const id = anchor
    .split(' ')
    .join('-')
    .toLowerCase();
  return <HeaderOffsetDiv id={id} />;
};

PageLandmark.propTypes = {
  anchor: PropTypes.string.isRequired,
};

export default PageLandmark;
