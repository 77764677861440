import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Wrapper from '../Wrapper';
import Text from '../ContentfulText';

const TileWrapper = styled(Wrapper)`
  position: relative;
`;

const Tiles = styled.div`
  display: flex;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.dropShadow.pageSection};
  border-radius: 16px;
  padding: 24px;
  background: white;
  z-index: 2;

  div:not(:last-of-type) {
    margin-bottom: 25px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      margin-bottom: 0;
    }
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0;
    background: transparent;
    justify-content: space-between;
    flex-direction: row;
    box-shadow: none;
    border-radius: unset;
  }

  p {
    margin: 0;
  }
`;

const Tile = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: white;

  p {
    &:first-of-type {
      font-size: ${({ theme }) => theme.fontSizes.mobileCallOut};
      margin-bottom: 4px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondaryGrey};

      @media ${({ theme }) => theme.breakpoints.tablet} {
        font-size: 1rem;
      }

      @media ${({ theme }) => theme.breakpoints.desktop} {
        margin-bottom: 8px;
      }
    }

    &:last-of-type {
      font-family: ${({ theme }) => theme.fonts.inter};
      font-size: ${({ theme }) => theme.fontSizes.mobileParagraph};
      color: ${({ theme }) => theme.colors.primaryGrey};
      line-height: 1.375rem;
    }
  }

  img {
    max-width: 64px;
    margin-right: 24px;
    align-self: flex-start;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      margin-right: 16px;
      align-self: center;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      max-width: 80px;
      margin-right: 24px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    border-radius: 16px;
    box-shadow: ${({ theme }) => theme.dropShadow.pageSection};
    padding: 24px;
    width: calc(100% / 3 - 16px);
    max-width: 378px;
  }
`;

const ValueTiles = ({ data }) => {
  const { valueTiles } = data;

  return (
    <TileWrapper>
      <Tiles>
        {valueTiles.map(({ icon, description, title, id }) => (
          <Tile key={id}>
            <img src={icon.asset.url} alt={icon.altText} aria-hidden />
            <div>
              <Text document={title.json} />
              <Text document={description.json} />
            </div>
          </Tile>
        ))}
      </Tiles>
    </TileWrapper>
  );
};

ValueTiles.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ValueTiles;
