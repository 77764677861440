import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RichText from '../ContentfulText';
import Wrapper from '../Wrapper';

import PropTypes from 'prop-types';

const Table = styled.table`
  margin: 24px 0;
  border-collapse: separate;
  border-spacing: 0;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    margin: 24px 0 64px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 40px 0 80px;
  }

  p {
    margin: 0;
  }

  th,
  td {
    padding: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryGrey4};
    vertical-align: middle;

    &:first-of-type {
      border-left: 1px solid ${({ theme }) => theme.colors.secondaryGrey4};
    }

    &:last-child {
      border-right: 1px solid ${({ theme }) => theme.colors.secondaryGrey4};
    }

    img {
      vertical-align: middle;
    }
  }

  thead {
    th:not(:first-of-type) {
      background-color: ${({ theme }) => theme.colors.primaryPink2};
      padding: 5px;
      width: 87px;

      @media ${({ theme }) => theme.breakpoints.tablet} {
        width: 169px;
        padding: 16px;
      }

      @media ${({ theme }) => theme.breakpoints.desktop} {
        padding: 32px;
        width: 216px;
      }

      img {
        height: auto;

        @media ${({ theme }) => theme.breakpoints.tablet} {
          width: 121px;
        }
        @media ${({ theme }) => theme.breakpoints.desktop} {
          width: 152px;
        }
      }
    }
  }

  tbody {
    td:not(:first-of-type) {
      text-align: center;
    }

    p {
      font-family: ${({ theme }) => theme.fonts.inter};
      font-size: ${({ theme }) => theme.fontSizes.mobileParagraph};
      line-height: 1.25rem;

      @media ${({ theme }) => theme.breakpoints.tablet} {
        font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
        line-height: ${({ theme }) => theme.lineHeights.desktopParagraph};
      }
    }
  }

  tr {
    &:first-child th:last-child {
      border-top-right-radius: 8px;
      @media ${({ theme }) => theme.breakpoints.tablet} {
        border-top-right-radius: 24px;
      }
    }

    &:first-child th:nth-child(2) {
      border-top-left-radius: 8px;
      @media ${({ theme }) => theme.breakpoints.tablet} {
        border-top-left-radius: 24px;
      }
    }

    &:first-child td:first-child {
      border-top: 1px solid ${({ theme }) => theme.colors.secondaryGrey4};
      border-top-left-radius: 24px;
    }

    &:last-child td:first-child {
      border-bottom-left-radius: 24px;
    }

    &:last-child td:last-child {
      border-bottom-right-radius: 24px;
    }

    &:first-of-type {
      th:first-of-type {
        border: none;
        p {
          font-size: ${({ theme }) => theme.fontSizes.mobileCallOut};
          line-height: 1.5rem;
          font-weight: 600;

          @media ${({ theme }) => theme.breakpoints.tablet} {
            font-size: ${({ theme }) => theme.fontSizes.tabletCallOut};
            line-height: ${({ theme }) => theme.lineHeights.tabletCallOut};
          }

          @media ${({ theme }) => theme.breakpoints.desktop} {
            font-size: ${({ theme }) => theme.fontSizes.desktopCallOut};
            line-height: 2.25rem;
          }
        }
      }
    }
  }
`;

const Cell = ({ index, content }) => {
  const child = <RichText document={content.json} />;
  return index > 0 ? <td>{child}</td> : <th scope="col">{child}</th>;
};

Cell.propTypes = {
  index: PropTypes.number.isRequired,
  content: PropTypes.shape({
    json: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      content: PropTypes.array,
      data: PropTypes.shape(),
      nodeType: PropTypes.string,
    }),
  }).isRequired,
};

const MainTable = ({ data }) => {
  const [componentLoaded, setComponentLoaded] = useState(false);
  useEffect(() => {
    setComponentLoaded(true);
  }, []);

  const { mainTableItems } = data;
  const tableItems = mainTableItems.map(({ id, text, row1, row2 }, index) => (
    <tr key={id}>
      <Cell index={index} content={text} />
      <Cell index={index} content={row1} />
      <Cell index={index} content={row2} />
    </tr>
  ));

  const firstRow = tableItems.slice(0, 1);
  const remainingItems = tableItems.slice(1, tableItems.length);

  return (
    <Wrapper>
      {componentLoaded && (
        <Table>
          <thead>{firstRow}</thead>
          <tbody>{remainingItems}</tbody>
        </Table>
      )}
    </Wrapper>
  );
};

MainTable.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default MainTable;
