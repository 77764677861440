import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from '../ContentfulText';
import ButtonLink from '../UI/ButtonLink';

const Hero = styled.section`
  color: white;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 37px 17px 82px;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    padding: 70px 40px 113px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 110px 77px 158px;
  }

  p {
    margin: 0;
  }
`;

const Badge = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryRed};
  font-family: ${({ theme }) => theme.fonts.inter};
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.mobileParagraph};
  text-align: center;
  margin-bottom: 8px;
  display: inline-block;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
    margin-bottom: 10px;
  }
`;

const StyledHero = styled.section`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.blueberry};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: repeat-x;
  background-position: bottom;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    background-size: 40%;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 32px 40px 0;
    border-radius: 25px;
  }

  @media (min-width: 1680px) {
    max-width: 1585px;
    margin: 32px auto 0;
  }
`;

const Image = styled.img`
  max-width: 100%;
`;

const ImageContainer = styled.div`
  width: 136px;
  margin-top: 24px;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: 242px;
    margin-top: 0;
    padding-top: 34px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 449px;
    padding-top: 38px;
  }
`;

const Content = styled.div`
  h1 {
    font-size: 2rem;
    line-height: 2.375rem;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: ${({ theme }) => theme.fontSizes.desktopHeader};
      line-height: 4.5rem;
      margin-bottom: 0;
    }
  }

  p {
    font-family: ${({ theme }) => theme.fonts.inter};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
    line-height: ${({ theme }) => theme.lineHeights.desktopParagraph};

    @media ${({ theme }) => theme.breakpoints.tablet} {
      max-width: 400px;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      max-width: 583px;
    }

    a {
      text-decoration: underline;
    }
  }

  p:empty {
    display: none;
  }

  a {
    margin-top: 16px;
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      width: 285px;
      margin-top: 32px;
    }
  }
`;

const PointsDaysHero = ({ data }) => {
  const {
    backgroundImage,
    title,
    description,
    badge,
    callToAction,
    image,
  } = data;

  return (
    <StyledHero backgroundImage={backgroundImage.asset.url}>
      <Hero>
        <Content>
          {badge && <Badge>{badge}</Badge>}
          <Text document={title.json} />
          {description && <Text document={description.json} />}
          {callToAction && (
            <ButtonLink
              route="external"
              url={callToAction.linkUrl}
              customStyle={[callToAction.style, 'block']}
            >
              {callToAction.linkText}
            </ButtonLink>
          )}
        </Content>
        <ImageContainer>
          <Image src={image.asset.url} alt={image.altText} aria-hidden />
        </ImageContainer>
      </Hero>
    </StyledHero>
  );
};

PointsDaysHero.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default PointsDaysHero;
