import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 7.5vw;

  @media screen and (max-width: 1600px) {
    margin: 0 6vw;
  }

  @media screen and (max-width: 1200px) {
    margin: 0 4.5vw;
  }

  @media screen and (max-width: 640px) {
    margin: 1rem;
  }

  @media screen and (min-width: 1441px) {
    margin: 0 auto;
    max-width: 1200px;
  }
`;

export default Wrapper;
