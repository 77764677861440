import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import Text from '../ContentfulText';
import Wrapper from '../Wrapper';

const Subscription = styled(Wrapper)`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: ${({ lang }) => (lang === 'fr' ? '425px' : '350px')};
  background-position: top 350px center;

  @media (min-width: 375px) {
    padding-top: ${({ lang }) => (lang === 'fr' ? '350px' : '280px')};
    background-position: top 290px center;
  }

  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding-top: 230px;
    background-position: top 160px center;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: 120px;
    background-position: top center;
  }

  @media (min-width: 1050px) {
    padding-top: 80px;
    background-position: top -80px center;
  }

  p:empty {
    display: none;
  }
`;

const SubscriptionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 38px 0 20px;
  flex-direction: column;
  margin: 24px 0;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
    margin: 0;
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.mobileCallOut};
    color: ${({ theme }) => theme.colors.secondaryGrey};
    text-align: center;
    margin-bottom: 5px;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      margin-bottom: 0;
      text-align: right;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: ${({ theme }) => theme.fontSizes.desktopSubscriptionHeader};
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.primaryPink};
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      flex-direction: row;
      margin-left: 14px;
      white-space: pre;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: ${({ theme }) => theme.fontSizes.desktopHeader};
    }

    sub {
      font-size: 1rem;

      @media ${({ theme }) => theme.breakpoints.tablet} {
        font-size: 0.875rem;
      }

      @media ${({ theme }) => theme.breakpoints.desktop} {
        font-size: ${({ theme }) => theme.fontSizes.desktopSubscriptionHeader};
      }
    }
  }
`;

const SubscriptionTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  box-shadow: ${({ theme }) => theme.dropShadow.pageSection};
  background-size: cover;
  border-radius: 24px;
  padding: 24px;

  &:nth-of-type(odd) {
    img {
      @media ${({ theme }) => theme.breakpoints.tablet} {
        position: relative;
        right: 16px;
      }
      @media ${({ theme }) => theme.breakpoints.desktop} {
        right: 32px;
      }
    }
  }

  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding: 48px;
    flex-direction: row;

    &:nth-of-type(odd) {
      h2 {
        order: 1;
      }
    }
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 64px;
  }

  &:not(:last-of-type) {
    margin-bottom: 32px;
  }

  & > * {
    flex-basis: 50%;
    flex-shrink: 0;
  }

  h2 {
    font-size: 1.625rem;
    line-height: 1.875rem;
    color: ${({ theme }) => theme.colors.primaryPink};
    margin-bottom: 24px;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      margin-bottom: 0;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: 2.625rem;
      line-height: ${({ theme }) => theme.lineHeights.desktopCallOut};
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: auto;
    width: ${props => (props.index === 1 ? '240px' : '140px')};

    @media ${({ theme }) => theme.breakpoints.tablet} {
      width: ${props => (props.index === 1 ? '280px' : '140px')};
    }
    @media ${({ theme }) => theme.breakpoints.desktop} {
      width: ${props => (props.index === 1 ? '515px' : '245px')};
    }
  }

  @media ${({ theme }) => theme.breakpoints.tablet} {
    min-width: unset;
  }
`;

const AnnualSubscription = ({ data }) => {
  const { locale } = useIntl();
  const { backgroundImage, title, description, subscriptionTiles } = data;
  
  return (
    <Subscription lang={locale} backgroundImage={backgroundImage.asset.url}>
      <SubscriptionHeader>
        {title && <Text document={title.json} />}
        {description && <Text document={description.json} />}
      </SubscriptionHeader>
      {subscriptionTiles.map((tile, index) => (
        <SubscriptionTile
          backgroundImage={tile.backgroundImage.asset.url}
          key={tile.id}
        >
          <Text document={tile.title.json} />
          <ImageContainer index={index}>
            <img src={tile.image.asset.url} alt={tile.image.altText} />
          </ImageContainer>
        </SubscriptionTile>
      ))}
    </Subscription>
  );
};

AnnualSubscription.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default AnnualSubscription;
