import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import styled from 'styled-components';

import RichText from '../RichText';
// import Separator from '../UI/Separator';
import TextLink from '../UI/TextLink';
import { Wrapper, Heading, Content, SubHeading } from './styles';

const heading = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Heading>{children}</Heading>,
    [BLOCKS.HEADING_2]: (_, children) => <Heading>{children}</Heading>,
  },
};

const subHeadingOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <SubHeading>{children}</SubHeading>,
  },
};

const content = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Content>{children}</Content>,
    [INLINES.HYPERLINK]: (node, children) => (
      <TextLink TextLink customStyle={['red']} url={node.data.uri}>
        {children}
      </TextLink>
    ),
    [BLOCKS.UL_LIST]: (_, children) => <ul>{children}</ul>,
  },
};

const Separator = styled.hr`
  margin-top: 24px;
  border-top: none;
  border-bottom: 1px solid rgba(158, 147, 166, 0.16);
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    margin-top: 48px;
  }
`;

const TextBox = ({ data }) => {
  const {
    headingRTF,
    paragraphRTF,
    showNextSectionDividingLine,
    anchorName,
    textAlign,
    subHeading,
  } = data;

  return (
    <>
      {showNextSectionDividingLine && <Separator />}
      <Wrapper
        textAlign={textAlign}
        className="textBoxWrapper"
        id={anchorName && anchorName.replace(' ', '-')}
      >
        {headingRTF && (
          <RichText document={headingRTF.json} options={heading} />
        )}
        {subHeading && (
          <RichText document={subHeading.json} options={subHeadingOptions} />
        )}
        {paragraphRTF && (
          <RichText document={paragraphRTF.json} options={content} />
        )}
      </Wrapper>
    </>
  );
};

TextBox.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default TextBox;
